import { Injectable } from '@angular/core';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';


@Injectable({ providedIn: 'root' })
export class IconService {


    constructor(private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer) {

    }
    connectIcons() {
        this.matIconRegistry.addSvgIcon(
            'chats-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/chats-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'dashboard-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/dashboard-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'filter-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/filter-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'logout-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/logout-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'mdCheveronLeft-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/md-cheveron-left.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'mdCheveronUp-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/md-cheveron-up-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'mdPrinter-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/md-printer-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'plus-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/plus-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'schedule-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/schedule-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'settings-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/settings-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'shifts-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/shifts-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'sort-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/sort-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'timecards-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/timecards-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'customShift-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/custom-old.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'dayShift-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/day-shift-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'eveningShift-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/evening-shift-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'nightShift-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/night-shift-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'mdCheckCircle-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/md-check-circle-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'mdExclamationCircle-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/md-exclamation-circle-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'mdPlusCircle-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/md-plus-circle-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'mdStop-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/md-stop-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'mdXCircle-01',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/md-x-circle-01.svg'),
        );

        this.matIconRegistry.addSvgIcon(
            'icon-noonshift',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/evening-shift-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'icon-dayshift',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/day-shift-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'icon-nightshift',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/night-shift-01.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'icon-mobile',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/mobile.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'icon-custom',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/custom.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'bubble',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/bubble.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'clip',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/clip.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'holidayPay',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/holiday-pay.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'danger',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/danger.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'warning',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/warning.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'success',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/success.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'info',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/info.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'dotsHorizontalCircle',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/dots-horizontal-circle.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'clock',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/clock.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'suspended',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/disabled.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'edit',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-redit.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'delete',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/delete.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'close',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/close.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'copy',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/copy.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'applicants',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/applicants.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'coin',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/coin.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'info-2',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/info-2.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'date',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-date.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'exportFm',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/export-fm.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'checkboxInput',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/checkbox-input.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'checkboxIndeterminate',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/checkbox-indeterminate.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'instaShift',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/instaShift.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'logs',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/logs.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'menu-alt',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/menu-alt.svg'),
        );
        this.matIconRegistry.addSvgIcon(
          'approvedBy',
          this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/approved-by.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'rateHistory',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/rate-history.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'questionMarkCircle',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/question-mark-circle.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'nurseDirectory',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/nurse-directory.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'badgeCheck',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/badge-check.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'clock2',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/clock-2.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'sortAscending',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/sort-ascending.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'arrowBackCircle',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/arrow-back-circle.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'badgeCheck2',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/badge-check-2.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'lastWorkedDay',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/last-worked-day.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'home',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/home.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'editAdd',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/edit-add.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'timer',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/timer.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'userAdd',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/user-add.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'lightningBolt',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/lightning-bolt.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'lightBulb',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/light-bulb.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'externalLink',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/external-link.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'userTick',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/user-tick.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'flag',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/flag.svg'),
        );
        
    }
}
