import { createAction, union, props } from '@ngrx/store';

import { type } from 'src/app/state/actions/helpers/action-type';

import { ConfirmApplicantDTO } from './dto/confirm-applicant';
import { CancelApplicantDTO } from './dto/cancel-applicant';
import { CreateShiftsDTO } from './dto/create-shifts';
import { DeclineApplicantDTO } from './dto/decline-applicant';
import { DeleteShiftDTO } from './dto/delete-shift';
import { EditShiftDTO } from './dto/edit-shift';
import { FetchNextShiftsPageDTO } from './dto/fetch-next-shifts-page';
import { FetchShiftsDTO } from './dto/fetch-shifts';
import { FetchShiftDTO } from './dto/fetch-shift';
import { FetchPageDTO } from './dto/fetch-page';
import { FetchScheduledApplicantsDTO } from './dto/fetch-scheduled-applicants';
import { FetchShiftsForScheduleDTO } from './dto/fetch-shifts-for-schedule';
import { UndoNcnsDTO } from './dto/undo-ncns';
import { ReportNcnsDTO } from './dto/report-ncns';

const t = type('shifts');

export const fetchShifts = createAction(t.pending('fetch_shifts'), props<FetchShiftsDTO.Pending>());
export const fetchShiftsSuccess = createAction(t.success('fetch_shifts'), props<FetchShiftsDTO.Success>());
export const fetchShiftsFailed = createAction(t.failed('fetch_shifts'), props<FetchShiftsDTO.Failed>());

export const fetchShift = createAction(t.pending('fetch_shift'), props<FetchShiftDTO.Pending>());
export const fetchShiftSuccess = createAction(t.success('fetch_shift'), props<FetchShiftDTO.Success>());
export const fetchShiftFailed = createAction(t.failed('fetch_shift'), props<FetchShiftDTO.Failed>());

export const fetchShiftsForSchedule = createAction(t.pending('fetch_shifts_for_schedule'), props<FetchShiftsForScheduleDTO.Pending>());
export const fetchShiftsForScheduleSuccess = createAction(t.success('fetch_shifts_for_schedule'), props<FetchShiftsForScheduleDTO.Success>());
export const fetchShiftsForScheduleFailed = createAction(t.failed('fetch_shifts_for_schedule'), props<FetchShiftsForScheduleDTO.Failed>());


export const fetchNextShiftsPage = createAction(t.pending('fetch_next_shifts_page'), props<FetchNextShiftsPageDTO.Pending>());
export const fetchNextShiftsPageSuccess = createAction(t.success('fetch_next_shifts_page'), props<FetchNextShiftsPageDTO.Success>());
export const fetchNextShiftsPageFailed = createAction(t.failed('fetch_next_shifts_page'), props<FetchNextShiftsPageDTO.Failed>());

export const fetchPage = createAction(t.pending('fetch_page'), props<FetchPageDTO.Pending>());
export const fetchPageSuccess = createAction(t.success('fetch_page'), props<FetchPageDTO.Success>());
export const fetchPageFailed = createAction(t.failed('fetch_page'), props<FetchPageDTO.Failed>());

export const confirmApplicant = createAction(t.pending('approve_applicant'), props<ConfirmApplicantDTO.Pending>());
export const confirmApplicantSuccess = createAction(t.success('approve_applicant'), props<ConfirmApplicantDTO.Success>());
export const confirmApplicantFailed = createAction(t.failed('approve_applicant'), props<ConfirmApplicantDTO.Failed>());

export const declineApplicant = createAction(t.pending('decline_applicant'), props<DeclineApplicantDTO.Pending>());
export const declineApplicantSuccess = createAction(t.success('decline_applicant'), props<DeclineApplicantDTO.Success>());
export const declineApplicantFailed = createAction(t.failed('decline_applicant'), props<DeclineApplicantDTO.Failed>());

export const cancelApplicant = createAction(t.pending('cancel_applicant'), props<CancelApplicantDTO.Pending>());
export const cancelApplicantSuccess = createAction(t.success('cancel_applicant'), props<CancelApplicantDTO.Success>());
export const cancelApplicantFailed = createAction(t.failed('cancel_applicant'), props<CancelApplicantDTO.Failed>());

export const reportNcns = createAction(t.pending('report_ncns'), props<ReportNcnsDTO.Pending>());
export const reportNcnsSuccess = createAction(t.success('report_ncns'), props<ReportNcnsDTO.Success>());
export const reportNcnsFailed = createAction(t.failed('report_ncns'), props<ReportNcnsDTO.Failed>());

export const undoNcns = createAction(t.pending('undo_ncns'), props<UndoNcnsDTO.Pending>());
export const undoNcnsSuccess = createAction(t.success('undo_ncns'), props<UndoNcnsDTO.Success>());
export const undoNcnsFailed = createAction(t.failed('undo_ncns'), props<UndoNcnsDTO.Failed>());

export const deleteShift = createAction(t.pending('delete_shift'), props<DeleteShiftDTO.Pending>());
export const deleteShiftSuccess = createAction(t.success('delete_shift'), props<DeleteShiftDTO.Success>());
export const deleteShiftFailed = createAction(t.failed('delete_shift'), props<DeleteShiftDTO.Failed>());

export const editShift = createAction(t.pending('edit_shift'), props<EditShiftDTO.Pending>());
export const editShiftSuccess = createAction(t.success('edit_shift'), props<EditShiftDTO.Success>());
export const editShiftFailed = createAction(t.failed('edit_shift'), props<EditShiftDTO.Failed>());

export const createShifts = createAction(t.pending('create_shifts'), props<CreateShiftsDTO.Pending>());
export const createShiftsSuccess = createAction(t.success('create_shifts'), props<CreateShiftsDTO.Success>());
export const createShiftsFailed = createAction(t.failed('create_shifts'), props<CreateShiftsDTO.Failed>());

export const fetchScheduledApplicants = createAction(t.pending('fetch_scheduled_applicants'), props<FetchScheduledApplicantsDTO.Pending>());
export const fetchScheduledApplicantsSuccess = createAction(t.success('fetch_scheduled_applicants'), props<FetchScheduledApplicantsDTO.Success>());
export const fetchScheduledApplicantsFailed = createAction(t.failed('fetch_scheduled_applicants'), props<FetchScheduledApplicantsDTO.Failed>());

export const removeShiftFromStore = createAction(t.sync('remove_shift_from_store'), props<{ shiftId: string }>());
export const updateApplicantStatusToSuspended = createAction(t.sync('update_applicant_status'), props<{ applicantId: string, shiftId: string}>());


