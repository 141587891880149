import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { Applicant } from 'src/app/models/applicant';
import { Facility, FacilityModel } from 'src/app/models/facility';
import { Shift } from 'src/app/models/shift';
import { ApplicantWithBindings } from 'src/app/modules/manager/bindings';
import { ShiftsAccessor } from 'src/app/modules/manager/services/accessors/shifts.accessor';
import { ShiftsFacade } from 'src/app/modules/manager/services/facades/shifts/shifts.facade';
import { IntercomService } from 'src/app/modules/manager/services/intercom.service';
import { MessagesService } from 'src/app/modules/manager/services/messages.service';
import { ReportNcnsButtonService } from 'src/app/modules/manager/services/report-ncns-button.service';
import { ModalService } from 'src/app/modules/manager/services/ui/modal.service';
import { MixpanelService } from 'src/app/services/mixpanel.service';
import { ShiftsService } from '../../../services/api/shifts.service';

@Component({
  selector: 'app-ncns-button',
  templateUrl: './ncns-button.component.html',
  styleUrls: ['./ncns-button.component.scss'],
  providers: [ReportNcnsButtonService] 
})
export class NcnsButtonComponent implements OnInit {

  @Input() applicant: ApplicantWithBindings;
  @Input() shift: Shift;
  @Input() facility: FacilityModel;

  showUndoNcns: boolean;
  canUndoNcns: boolean;
  showReportNcns: boolean;
  canReportNcns: boolean;
  clockedInThisShift: boolean;
  clockedInOtherShiftThisFacility: boolean;
  clockedOutThisShift: boolean;
  oldShift: boolean;
  buttonEnabled: boolean;
  buttonState$ = this.ncnsButtonService.getButtonState$();
  buttonEnabled$ = this.ncnsButtonService.getButtonEnabled$();
  tooltipState$ = this.ncnsButtonService.getTooltipState$();
  lastClockedInShift: string;
  tooltipTemplate: TemplateRef<any>;
  buttonSubs: Subscription[] = [];
  offsetX: number = -110;
  offsetY: number = -15;
  @Input() buttonWidth = '116px';
  @Input() nurseProfile: boolean = false;

  @ViewChild('btwnStartAndThirtyMinNotClockedIn') btwnStartAndThirtyMinNotClockedIn: TemplateRef<any>;
  @ViewChild('clockedInBeforeReportNcns') clockedInBeforeReportNcns: TemplateRef<any>;
  @ViewChild('btwnStartAndThirtyMinClockedInOther') btwnStartAndThirtyMinClockedInOther: TemplateRef<any>;
  @ViewChild('undoTimePassed') undoTimePassed: TemplateRef<any>;
  @ViewChild('nurseClockedInAfterReportNcns') nurseClockedInAfterReportNcns: TemplateRef<any>;
  @ViewChild('shiftOver') shiftOver: TemplateRef<any>;
  tooltipStateDictionary: {};

  constructor(private ncnsButtonService: ReportNcnsButtonService, 
              private shiftsAccessor: ShiftsAccessor, 
              private modalService: ModalService, 
              private router:Router, 
              private intercomService: IntercomService,
              private shiftsFacade: ShiftsFacade,
              private messages: MessagesService,
              private cdr: ChangeDetectorRef,
              private mixpanel: MixpanelService,
              private shiftsService: ShiftsService) { }

  ngOnInit(): void {
    this.getClockedInToOtherShiftThisFacility();
    this.getClockedInToThisShift();
    if(this.applicant.status != 'confirmed') {
      this.setButtonState("confirm");
      this.ncnsButtonService.clearTimeouts();
    }    
    else {
      this.trackButtonState();
      this.buttonSubs.push(this.buttonState$.subscribe(s => { this.setButtonState(s); }));
      this.buttonSubs.push(this.buttonEnabled$.subscribe(s => { this.setButtonEnabled(s); }));
    }
    }

  ngAfterViewInit() {
    this.tooltipStateDictionary = {
      'btwnStartAndThirtyMinNotClockedIn':this.btwnStartAndThirtyMinNotClockedIn,
      'clockedInBeforeReportNcns': this.clockedInBeforeReportNcns,
      'btwnStartAndThirtyMinClockedInOther': this.btwnStartAndThirtyMinClockedInOther,
      'undoTimePassed': this.undoTimePassed,
      'nurseClockedInAfterReportNcns': this.nurseClockedInAfterReportNcns,
      'shiftOver': this.shiftOver
    }
    this.buttonSubs.push(this.tooltipState$.subscribe(s => {this.tooltipTemplate = this.tooltipStateDictionary[s]; console.log(this.tooltipTemplate);
       if(s == 'btwnStartAndThirtyMinNotClockedIn' && !this.nurseProfile) {
        this.offsetX = -190;
        this.cdr.detectChanges();
       }
       else {
        this.offsetX = -110;
       }
      }));
  }

  setButtonState(s) {
    if(s == "report") {
      this.showReportNcns = true;
      this.showUndoNcns = false;
    }
    else if(s == "undo") {
      this.showUndoNcns = true;
      this.showReportNcns = false;
    }
    else {
      this.showReportNcns = false;
      this.showUndoNcns = false;
    }
  }

  setButtonEnabled(s) {
    if(this.showReportNcns) this.canReportNcns = true
    if(this.showUndoNcns) this.canUndoNcns = true;
    this.buttonEnabled = s;
  }


  getClockedInToThisShift() {
    if(this.shift._id == this.applicant.facilityNurse.lastClockedInShift){
      this.clockedInThisShift = true;
    } 
    else {
      this.clockedInThisShift = false;
    }
  }

  getClockedInToOtherShiftThisFacility() {
    if(this.applicant.facilityNurse.lastClockedInShift != this.applicant.facilityNurse.lastClockedOutShift) {
      this.shiftsService.fetchShift(this.applicant.facilityNurse.lastClockedInShift).pipe(take(1)).subscribe(shift => {
        this.clockedInOtherShiftThisFacility = shift.facility._id == this.facility._id; //if shift doesn't exist on this facility, then it's at another one
        this.trackButtonState();
    }
    )}
  }

  trackButtonState() {
    this.ncnsButtonService.trackButtonState(this.shift.shiftFrom, 
      this.shift.shiftTo, 
      this.facility.timezone, 
      this.clockedInThisShift, 
      this.clockedInOtherShiftThisFacility,
      this.clockedOutThisShift,
      this.applicant.ncnsReportedAt);
  };

  getClockedOutOfThisShift() {
    if(this.shift._id == this.applicant.facilityNurse.lastClockedInShift && this.applicant.facilityNurse.lastClockedInShift == this.applicant.facilityNurse.lastClockedOutShift) {
      this.clockedOutThisShift = true;
  }}

  getPageLocation() {
    return this.nurseProfile ? "Nurse Profile" : "Shifts Page";
  }

  onUndoNcns(applicant) {
    this.mixpanel.for('fm').track('OPEN_UNDO_NCNS', { shift: this.shift, facility: this.facility, pageLocation: this.getPageLocation(), buttonStyle: "White Button", buttonType: "Action Button" });
    this.modalService.openModal("Are you sure you want to undo this NCNS?", "You can undo a NCNS within 2 minutes of reporting it.", "Undo NCNS", "Cancel", "primary")
    .pipe(take(1))
    .subscribe(r => { 
      if(r.action != "closeAction"){
      this.undoNcns(applicant);
      this.mixpanel.for('fm').track('CONFIRM_UNDO_NCNS', { shift: this.shift, facility: this.facility, applicant: this.applicant, pageLocation: this.getPageLocation(), buttonStyle: "Blue Button", buttonType: "Action Button" });
    }})
  }

  onReportNcns(applicant: Applicant) {
    this.mixpanel.for('fm').track('OPEN_REPORT_NCNS', { shift: this.shift, facility: this.facility, pageLocation: this.getPageLocation(), buttonStyle: "Red Button", buttonType: "Action Button" });
    const applicantName = applicant.user.name.first;
    if(this.clockedInOtherShiftThisFacility) {
      this.modalService.openModal("Report NCNS", "This nurse is clocked in to another shift at your facility, you can’t report them as NCNS.", "View Shift", "Cancel", "primary")
      .pipe(take(1))
      .subscribe(r => {
        if(r.action != "closeAction") {
         this.router.navigate(['/manager/facility-selection']).then(() => { 
         this.router.navigate(['manager/shifts/applicants'], 
            { state: { id: applicant.facilityNurse.lastClockedInShift } })
         });
        }
      });
    }
    else {
      this.modalService.openModal("Are you sure you want to report a NCNS (No Call No Show)?", 
        "Reporting " + applicantName + " as a NCNS may result in "+  applicantName + "'s account being suspended. " + applicantName + " can still clock into this shift after you report a NCNS, which will remove the NCNS.",
        "Report NCNS", "Cancel", "rn", "modal", true, "I confirm " + applicantName + " did not contact me and did not show up to this shift.", "", true,
        true, "I understand that reporting a NCNS may result in " + applicantName + " 's account being suspended.", true, true)
        .pipe(take(1))
        .subscribe(r => {
        if(r.action != "closeAction"){
          this.reportNcns(applicant);
          this.mixpanel.for('fm').track('CONFIRM_REPORT_NCNS', { shift: this.shift, facility: this.facility, applicant: this.applicant, pageLocation: this.getPageLocation(), buttonStyle: "Red Button", buttonType: "Action Button" });
        }
      });
    }

  }

  private reportNcns(applicant) {
    this.shiftsFacade.reportNcns(this.shift._id, applicant.user._id)
    .pipe(take(1))
    .subscribe(r => {
      if (!r['error']) {
        this.messages.add('Success! Your change has been updated.', '', 'success');
        this.ncnsButtonService.onReportNcnsClick();
        this.shiftsFacade.fetchShift(this.shift._id).pipe(take(1)).subscribe(x => console.log(x));
      }
      else
      {
        this.messages.add(r['error'].error.message, '', 'danger');
      }
    });
  }

  private undoNcns(applicant) {
    this.shiftsFacade.undoNcns(this.shift._id, applicant.user._id)
    .pipe(take(1))
    .subscribe(r => {
      if(!r['error']){
        this.messages.add('Success! Your change has been updated.', '', 'success');
        this.ncnsButtonService.onUndoNcnsClick();
        this.shiftsFacade.fetchShift(this.shift._id).pipe(take(1)).subscribe(x => console.log(x));
      }
      else {
        this.messages.add(r['error'].error.message, '', 'danger');
      }
    });
  }

  viewClockedInShift() {
    this.router.navigate(['/manager/facility-selection']).then(() => {this.router.navigate(['manager/shifts/applicants'], { state: { id: this.applicant.facilityNurse.lastClockedInShift } })});
  }

  openIntercom() {
    this.mixpanel.for('fm').track('NCNS_INTERCOM', { shift: this.shift, facility: this.facility, pageLocation: this.getPageLocation(), buttonStyle: "Hyperlink", buttonType: "Navigation Button", linkDestination: 'Intercom' });
    this.intercomService.displayMessenger();
  }

  ngOnDestroy(){
    this.ncnsButtonService.clearTimeouts();
    this.buttonSubs.forEach(s => s.unsubscribe());
  }


}
