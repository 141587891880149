import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import {MatSliderModule} from '@angular/material/slider'; 

import { DirectivesModule } from '../directives/directives.module';

import { HeaderComponent } from './header/header.component';
import { SubheaderedContentWrapperComponent } from './subheadered-content-wrapper/subheadered-content-wrapper.component';
import { FiltersComponent } from './filters/filters.component';
import { RangeDatepickerComponent } from './filters/range-datepicker/range-datepicker.component';
import { CheckboxesFilterComponent } from './filters/checkboxes-filter/checkboxes-filter.component';
import { SortingSubheaderComponent } from './sorting-subheader/sorting-subheader.component';
import { InputComponent } from './input/input.component';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { ModalBoxComponent } from './modal-box/modal-box.component';
import { MatSelectModule } from '@angular/material/select';
import { BadgeComponent } from './badge/badge.component';
import { NurseProfileComponent } from './nurse-profile/nurse-profile.component';
import { ActionToastComponent } from './action-toast/action-toast.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { HolidayPayComponent } from './holiday-pay/holiday-pay.component';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';
import { SimpleRangeDatepickerComponent } from './filters/simple-range-datepicker/simple-range-datepicker.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ActivityLogsComponent } from './activity-logs/activity-logs.component';
import { DnrModalComponent } from './nurse-profile/dnr-modal/dnr-modal.component';
import { AddToDnrModalComponent } from './nurse-profile/dnr-modal/add-to-dnr-modal/add-to-dnr-modal.component';
import { RemoveDnrModalComponent } from './nurse-profile/dnr-modal/remove-dnr-modal/remove-dnr-modal.component';
import { HistoryDnrModalComponent } from './nurse-profile/dnr-modal/history-dnr-modal/history-dnr-modal.component';
import { InstashyftTooltipComponent } from './instashyft-tooltip/instashyft.component';
import { SidePopupComponent } from './side-popup/side-popup.component';
import { TimeInputComponent } from './time-input/time-input.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CreateEditShiftComponent } from './create-edit-shift/create-edit-shift.component';
import { FacilityBillRateComponent } from './facility-bill-rate/facility-bill-rate.component';
import { SliderInputComponent } from './slider-input/slider-input.component';
import { MatInputModule } from '@angular/material/input';
import { RemoveFromTrustedPopupComponent } from './remove-from-trusted-popup/remove-from-trusted-popup.component';
import { ShiftTimeModalComponent } from './shift-time-modal/shift-time-modal.component';
import { FacilitySearchSelectComponent } from './nurse-profile/dnr-modal/facility-search-select/facility-search-select.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CreateShiftPopupComponent } from './header/create-shift-popup/create-shift-popup.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { NcnsButtonComponent } from './ncns-button/ncns-button.component';
import { FeatureBannerComponent } from './feature-banner/feature-banner.component';

@NgModule({
  declarations: [
    CheckboxesFilterComponent,
    FiltersComponent,
    HeaderComponent,
    RangeDatepickerComponent,
    SubheaderedContentWrapperComponent, 
    SortingSubheaderComponent,
    InputComponent,
    ModalBoxComponent,
    BadgeComponent,
    NurseProfileComponent,
    ActionToastComponent,
    PaginatorComponent,
    HolidayPayComponent,
    CustomTooltipComponent,
    SimpleRangeDatepickerComponent,
    ActivityLogsComponent,
    DnrModalComponent,
    AddToDnrModalComponent,
    RemoveDnrModalComponent,
    HistoryDnrModalComponent,
    InstashyftTooltipComponent,
    SidePopupComponent,
    TimeInputComponent,
    CreateEditShiftComponent,
    FacilityBillRateComponent,
    SliderInputComponent,
    RemoveFromTrustedPopupComponent,
    ShiftTimeModalComponent,
    FacilitySearchSelectComponent,
    CreateShiftPopupComponent,
    SelectInputComponent,
    NcnsButtonComponent,
    FeatureBannerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    DirectivesModule,
    FormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatIconModule,
    MatSelectModule,
    OverlayModule,
    TextFieldModule,
    MatSliderModule,
    MatInputModule,
    MatCheckboxModule
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  exports: [
    FiltersComponent,
    HeaderComponent,
    SubheaderedContentWrapperComponent,
    SortingSubheaderComponent,
    InputComponent,
    ModalBoxComponent,
    BadgeComponent,
    NurseProfileComponent,
    PaginatorComponent,
    CheckboxesFilterComponent,
    HolidayPayComponent,
    SimpleRangeDatepickerComponent,
    InstashyftTooltipComponent,
    ActivityLogsComponent,
    SidePopupComponent,
    TimeInputComponent,
    CreateEditShiftComponent,
    FacilityBillRateComponent,
    SliderInputComponent,
    RemoveFromTrustedPopupComponent,
    ShiftTimeModalComponent,
    SelectInputComponent,
    CreateShiftPopupComponent,
    NcnsButtonComponent,
    FeatureBannerComponent

  ],
})
export class ComponentsModule {}
