import { Directive, Input, TemplateRef, ElementRef, OnInit, HostListener, ComponentRef, OnDestroy, SimpleChanges } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { CustomTooltipComponent } from './../../components/custom-tooltip/custom-tooltip.component';
import { ComponentPortal } from '@angular/cdk/portal';

@Directive({
  selector: '[customToolTip]'
})
export class ToolTipRendererDirective implements OnInit, OnDestroy {

  @Input() showToolTip: boolean = true;
  @Input('customToolTip') text: string;
  @Input() offsetY: number = 5;
  @Input() offsetX: number = 0;
  @Input() contentTemplate: TemplateRef<any>;

  private _overlayRef: OverlayRef;
  private _closeTimeoutId: any;

  constructor(private _overlay: Overlay,
              private _overlayPositionBuilder: OverlayPositionBuilder,
              private _elementRef: ElementRef) { }

  ngOnInit() {
    if (!this.showToolTip) {
      return;
    }

    const positionStrategy = this._overlayPositionBuilder
                                   .flexibleConnectedTo(this._elementRef)
                                   .withPositions([{
                                                      originX: 'center',
                                                      originY: 'top',
                                                      overlayX: 'center',
                                                      overlayY: 'bottom',
                                                      offsetY: this.offsetY,
                                                      offsetX: this.offsetX,
                                                  }]);

    const overlayConfig = new OverlayConfig({
      positionStrategy: positionStrategy,
      scrollStrategy: this._overlay.scrollStrategies.close()
    });

    this._overlayRef = this._overlay.create(overlayConfig);
  }

  @HostListener('mouseenter')
  show() {
    if (this._closeTimeoutId) {
      clearTimeout(this._closeTimeoutId);
    }

    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      const tooltipPortal = new ComponentPortal(CustomTooltipComponent);
      const tooltipRef: ComponentRef<CustomTooltipComponent> = this._overlayRef.attach(tooltipPortal);
      tooltipRef.instance.contentTemplate = this.contentTemplate;


      this._overlayRef.overlayElement.addEventListener('mouseenter', this.onOverlayMouseEnter);
      this._overlayRef.overlayElement.addEventListener('mouseleave', this.onOverlayMouseLeave);
    }
  }

  @HostListener('mouseleave')
  hide() {
    this.startCloseTimer();
  }

  ngOnDestroy() {
    this.closeToolTip();
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  private startCloseTimer() {
    this._closeTimeoutId = setTimeout(() => {
      this.closeToolTip();
    }, 200); 
  }

  private closeToolTip() {
    if (this._closeTimeoutId) {
      clearTimeout(this._closeTimeoutId);
    }
    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this._overlayRef.detach();

      this._overlayRef.overlayElement.removeEventListener('mouseenter', this.onOverlayMouseEnter);
      this._overlayRef.overlayElement.removeEventListener('mouseleave', this.onOverlayMouseLeave);
    }
  }

  private onOverlayMouseEnter = () => {
    if (this._closeTimeoutId) {
      clearTimeout(this._closeTimeoutId);
    }
  }

  private onOverlayMouseLeave = () => {
    this.startCloseTimer();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['offsetX'] || changes['offsetY']) {
       this.updateTooltipPosition();
    }
  }

  updateTooltipPosition() {
    if (!this.showToolTip) {
      return;
    }
    const positionStrategy = this._overlayPositionBuilder
    .flexibleConnectedTo(this._elementRef)
    .withPositions([{
                       originX: 'center',
                       originY: 'top',
                       overlayX: 'center',
                       overlayY: 'bottom',
                       offsetY: this.offsetY,
                       offsetX: this.offsetX,
                   }]);

    const overlayConfig = new OverlayConfig({
    positionStrategy: positionStrategy,
    scrollStrategy: this._overlay.scrollStrategies.close()
    });

    this._overlayRef = this._overlay.create(overlayConfig);
  }
}