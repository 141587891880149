import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { SseService } from './sse.service'; // WebSocket service for handling events
import { DateTime } from 'luxon';

export type NcnsTooltipState = 'btwnStartAndThirtyMinNotClockedIn' | 'clockedInBeforeReportNcns' | 
'btwnStartAndThirtyMinClockedInOther' | 'betweenThirtyMinAndEndClockedIn' | 
'undoTimePassed' | 'nurseClockedInAfterReportNcns' | 'shiftOver'
@Injectable({
  providedIn: 'root'
})
export class ReportNcnsButtonService {
  private buttonState$ = new BehaviorSubject<'confirm' | 'report' | 'undo'>('confirm');
  private buttonEnabled$ = new BehaviorSubject<boolean>(false);
  private tooltipState$ = new BehaviorSubject<NcnsTooltipState>(null);
  private undoTimeout$ = new BehaviorSubject<boolean>(false);
  private timeouts: any[] = [];
  private startShiftTimeoutId: any;
  private undoTimeoutId: any;
  private timezone: string;
  

  constructor() { }

  // Track button state based on shift start, end, current time, and clock-in status
  public trackButtonState(
    shiftStartTime: DateTime,
    shiftEndTime: DateTime,
    timezone: string,
    clockedInToThisShift: boolean,
    clockedInToAnotherShift: boolean,
    clockedOutThisShift: boolean,
    ncnsReportedAt: string,
  ) {
    this.clearTimeouts();
    const currentTime = DateTime.now().setZone(timezone);
    const shiftStartMoment = shiftStartTime;
    const shiftEndMoment = shiftEndTime;
    this.timezone = timezone;

    if (currentTime < shiftStartMoment) { // Before shift start: button says confirm and disabled
      this.buttonState$.next('confirm');
      this.buttonEnabled$.next(false);
      const timeUntilStart = shiftStartMoment.diff(currentTime).as('milliseconds');
      this.startShiftTimeoutId = this.setTimeout(() => 
        this.startShiftTracking(shiftStartMoment, 
                                shiftEndMoment, 
                                clockedInToThisShift, 
                                clockedInToAnotherShift, 
                                clockedOutThisShift,
                                timezone, 
                                ncnsReportedAt),
      timeUntilStart);
    } else {  // If the shift has already started, start tracking immediately
      this.startShiftTracking(shiftStartMoment, 
                              shiftEndMoment, 
                              clockedInToThisShift, 
                              clockedInToAnotherShift, 
                              clockedOutThisShift,
                              timezone, 
                              ncnsReportedAt);
    }
  }

  // Start tracking the shift after it starts
  private startShiftTracking(
    shiftStartMoment: DateTime,
    shiftEndMoment: DateTime,
    clockedInToThisShift: boolean,
    clockedInToAnotherShift: boolean,
    clockedOutThisShift: boolean,
    timezone: string,
    ncnsReportedAt: string
  ): void {

    const currentTime = DateTime.now().setZone(timezone);
    const thirtyMinutesAfterStart = shiftStartMoment.plus({ minutes: 30 });
    if(ncnsReportedAt) {
      let twoMinutesToUndo = DateTime.fromISO(ncnsReportedAt, { zone: timezone }).plus({ minutes: 2 });
      let isWithinTwoMinutes = currentTime < twoMinutesToUndo;
      if(isWithinTwoMinutes) {
        this.onReportNcnsClick();
      }
      else {
        this.undoTimeout$.next(true);
        this.buttonState$.next('undo')
        this.buttonEnabled$.next(false);
      }
      if(clockedInToThisShift) {
        this.tooltipState$.next('nurseClockedInAfterReportNcns'); 
      }
      else {
        this.tooltipState$.next('undoTimePassed'); 
      }
    }
    else {
      // First 30 minutes: button is "report" but disabled
      if (currentTime < thirtyMinutesAfterStart) {
        this.buttonState$.next('report');
        this.buttonEnabled$.next(false);
        if(!clockedInToThisShift && !clockedInToAnotherShift) {this.tooltipState$.next('btwnStartAndThirtyMinNotClockedIn');}
        else if(clockedInToThisShift) {
          this.tooltipState$.next('clockedInBeforeReportNcns');
        }
        else if(clockedInToAnotherShift) {
          this.tooltipState$.next('btwnStartAndThirtyMinClockedInOther');
        }

        const timeUntil30MinutesAfterStart = thirtyMinutesAfterStart.diff(currentTime).as('milliseconds');
        this.setTimeout(() => this.enableReportButton(shiftEndMoment, clockedInToThisShift, clockedInToAnotherShift), timeUntil30MinutesAfterStart);
      } else if (currentTime < shiftEndMoment) {
        // Beyond 30 minutes: enable or disable based on clock-in status
        this.buttonState$.next('report');
        this.enableReportButton(shiftEndMoment, clockedInToThisShift, clockedInToAnotherShift);
      } else if (currentTime > shiftEndMoment && !clockedInToThisShift && !clockedOutThisShift) {
        this.endShift();
      }
      else if(currentTime > shiftEndMoment && clockedInToThisShift || clockedOutThisShift) {
        this.buttonState$.next('report');
        this.buttonEnabled$.next(false);
        this.tooltipState$.next('clockedInBeforeReportNcns');
      }
    }
  }

  // Enable the report button and check clock-in status from parameters
  private enableReportButton(
    shiftEndMoment: DateTime,
    clockedInToThisShift: boolean,
    clockedInToAnotherShift: boolean
  ): void {
    if (clockedInToThisShift) {
      this.buttonEnabled$.next(false);
      this.tooltipState$.next('clockedInBeforeReportNcns');
    } else if(clockedInToAnotherShift) {
      this.buttonEnabled$.next(true);
      this.tooltipState$.next(null);
      //no tooltip, a popup appears and you can view the shift
    }
      else {
      this.buttonEnabled$.next(true);
      this.tooltipState$.next(null);
    }
    let currentTime = DateTime.now().setZone(this.timezone);
    const timeUntilShiftEnd = shiftEndMoment.diff(currentTime).as('milliseconds');
    this.setTimeout(() => {
        if(!clockedInToThisShift) { this.endShift() }
    }, timeUntilShiftEnd);
  }

  // End of shift: button says "report" and disable it
  private endShift(): void {
    this.buttonState$.next('report');
    this.buttonEnabled$.next(false);
    this.tooltipState$.next('shiftOver');
  }

  public onReportNcnsClick() {
    this.buttonState$.next('undo');
    this.buttonEnabled$.next(true);

    this.undoTimeoutId = this.setTimeout(() => {
      this.undoTimeout$.next(true);
      this.buttonEnabled$.next(false);
      this.tooltipState$.next('undoTimePassed');
     }, 120000); // 2 minutes for undo timeout
  }

  public onUndoNcnsClick() {
    this.clearUndoTimeout();
    this.buttonState$.next('report');
    this.buttonEnabled$.next(true);
    this.tooltipState$.next(null);
  }

  // Getters to expose button state and enabled status as observables
  public getButtonState$() {
    return this.buttonState$.asObservable();
  }

  public getButtonEnabled$() {
    return this.buttonEnabled$.asObservable();
  }

  public getTooltipState$() {
    return this.tooltipState$.asObservable();
  }

  // Helper to set timeouts and manage them
  private setTimeout(callback: Function, delay: number) {
    const timeout = setTimeout(() => {
      callback();
      this.clearTimeout(timeout);
    }, delay);
    this.timeouts.push(timeout);
    return timeout;
  }

  public clearTimeouts() {
    this.timeouts.forEach(timeout => clearTimeout(timeout));
    this.timeouts = [];
  }

  private clearTimeout(timeout: any) {
    const index = this.timeouts.indexOf(timeout);
    if (index !== -1) {
      clearTimeout(timeout);
      this.timeouts.splice(index, 1);
    }
  }

  clearStartTimeout() {
    if (this.startShiftTimeoutId !== null) {
      clearTimeout(this.startShiftTimeoutId);
      this.startShiftTimeoutId = null;  // Reset the timeout ID to avoid accidental reuse
    }
  }

  private clearUndoTimeout() {
    console.log(this.undoTimeoutId);
    if (this.undoTimeoutId) {
      clearTimeout(this.undoTimeoutId); // Clear the existing timeout
      this.undoTimeoutId = null; // Reset the timeout reference
    }
  }

}
