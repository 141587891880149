import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { take } from 'rxjs';
import { UserAccessor } from 'src/app/services/accessors/user.accessor';

@Injectable({
  providedIn: 'root',
})
export class LastShiftPostedStorageService {

  public autoConfirmationStorageKey = 'lastAutoConfirmationPosted';
  public lastShiftStorageKey = 'lastShiftPosted';

  private userId = '';

  constructor(private userAccessor: UserAccessor) {
    this.userAccessor.user$.pipe(take(1)).subscribe((u) => {
      this.userId = u._id;
    });
  }
  
  saveDate(storageKey): void {
    const date = DateTime.now().toISO();
    let data = this.getLastPosted(storageKey);
    if (!data) {
      data = {};
    }
    const closed = data[this.userId]?.closed ? true : false;

    data[this.userId] = { date, closed };

    localStorage.setItem(storageKey, JSON.stringify(data));
  }

  getLastPosted(storageKey): Record<string, { date: string, closed: boolean }> | null {
    const storedData = localStorage.getItem(storageKey);
    return storedData ? JSON.parse(storedData) : null;
  }

  getDate(storageKey): DateTime | null {
    const data = this.getLastPosted(storageKey);
    if (data && data[this.userId] && data[this.userId].date) {
      return DateTime.fromISO(data[this.userId].date);
    }
    return null;
  }

  isClosed(storageKey): boolean | null {
    const data = this.getLastPosted(storageKey);
    if (data && data[this.userId]) {
      return data[this.userId].closed;
    }
    return false;
  }
  editClosedStatus(closed: boolean, storageKey): void {
    let data = this.getLastPosted(storageKey);
  
    if (!data) {
      data = {};
    }
    if (!data[this.userId]) {
      const date = null;
      data[this.userId] = { date, closed };
    } else {
      data[this.userId].closed = closed;
    }
  
    localStorage.setItem(storageKey, JSON.stringify(data));
  }
}
