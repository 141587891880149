
  <div *ngIf="showReportNcns; else showUndoReportNcns">
    <button class="ncns-button"
    [color]="'danger'"
    fill="solid"
    [style.width]="buttonWidth"
    [ngClass]="{
      disabled: !buttonEnabled
    }"
    [disabled]="!buttonEnabled
    "
    (click)="onReportNcns(applicant)"
    customToolTip
    [contentTemplate]="tooltipTemplate" 
    [offsetY]="offsetY" 
    [offsetX]="offsetX"
    >
    Report NCNS
    </button>
  </div>
 <ng-template #showUndoReportNcns>
    <button class="ncns-button"
    [color]="'primary'"
    fill="outline-mono"
    [style.width]="buttonWidth"
    [ngClass]="{
      disabled: !buttonEnabled
    }"
    [disabled]="!buttonEnabled"
    (click)="onUndoNcns(applicant)"
    *ngIf="facility.deactivationStatus != 'Disabled'"
    customToolTip
    [contentTemplate]="tooltipTemplate"  
    [offsetY]="offsetY" 
    [offsetX]="offsetX"
    >
    Undo NCNS
    </button>
 </ng-template>
 <ng-template #btwnStartAndThirtyMinNotClockedIn>
  <div class="tooltip-block" [ngClass]="{'before-thirty-min': !nurseProfile, 'before-thirty-min-profile': nurseProfile}">
    <span>You can report a NCNS 30 minutes after shift start time.</span>
  </div>
</ng-template>
<ng-template #clockedInBeforeReportNcns>
  <div class="tooltip-block" [ngClass]="{'profile': nurseProfile}">
    <span>The nurse clocked into this shift, you can't report them as NCNS. If you have any issues contact our <a (click)="openIntercom()" class="nowrap"><mat-icon svgIcon="questionMarkCircle"></mat-icon> Support team. </a></span>
  </div>
</ng-template>
<ng-template #btwnStartAndThirtyMinClockedInOther>
  <div class="tooltip-block" [ngClass]="{'profile': nurseProfile}">
    <span>This nurse is clocked in to another shift at your facility, you can’t report them as NCNS. <a (click)="viewClockedInShift()" class="nowrap">View clocked in shift.</a></span>
  </div>
</ng-template>
<ng-template #shiftOver>
  <div class="tooltip-block" [ngClass]="{'profile': nurseProfile}">
    <span>This shift is over, you can't report this nurse as a NCNS for it. If you have any issues contact our <a (click)="openIntercom()" class="nowrap"><mat-icon svgIcon="questionMarkCircle"></mat-icon> Support team. </a></span>
  </div>
</ng-template>
 <ng-template #undoTimePassed>
  <div class="tooltip-block" [ngClass]="{'profile': nurseProfile}">
    <span>Undo NCNS is available only for 2 minutes, if you still want to undo this action contact our <a (click)="openIntercom()" class="nowrap"><mat-icon svgIcon="questionMarkCircle"></mat-icon> Support team.</a></span>
  </div>
</ng-template>
<ng-template #nurseClockedInAfterReportNcns>
  <div class="tooltip-block">
    <span>The nurse clocked into this shift after you reported a NCNS. The NCNS was automatically removed.</span>
  </div>
</ng-template>
