import { createReducer, on } from '@ngrx/store';

import { SHIFTS_PAGE_SIZE } from 'src/app/constants/pagination';
import { toRecord } from 'src/app/state/helpers/to-record';
import { ShiftsActions } from '../actions/shifts/shifts-action-types';


import { ShiftsState } from '../interfaces';

export const initialShiftsState: ShiftsState = Object.freeze<ShiftsState>({
  models: {},
  pagination: {
    pagesCount: undefined,
    page: undefined,
    limit: SHIFTS_PAGE_SIZE,
  },
  shifts: [],
});

export const shiftsReducer = createReducer(
  initialShiftsState,
  on(ShiftsActions.fetchShiftsSuccess, ShiftsActions.fetchShiftsForScheduleSuccess, (state, { models }) => ({
    ...state,
    models: { ...state.models, ...toRecord(models, (s) => s._id) },
    shifts: models.map((s) => s._id),
  })),
  on(ShiftsActions.fetchNextShiftsPageSuccess, (state, { models }) => ({
    ...state,
    models: { ...state.models, ...toRecord(models, (s) => s._id) },
    shifts: state.shifts.concat(models.map((s) => s._id)),
  })),
  on(
    ShiftsActions.fetchPageSuccess,
    (state, { models, limit, page, pagesCount }) => ({
      ...state,
      models: { ...state.models, ...toRecord(models, (s) => s._id) },
      pagination: { limit, page, pagesCount },
      shifts: models.map((s) => s._id),
    }),
  ),
  on(
    ShiftsActions.declineApplicantSuccess,
    ShiftsActions.cancelApplicantSuccess,
    ShiftsActions.confirmApplicantSuccess,
    (state, { model }) => ({
      ...state,
      models: {
        ...state.models,
        [model._id]: {
          ...state.models[model._id],
          applicants: model.applicants,
        },
      },
    }),
  ),
  on(
    ShiftsActions.editShiftSuccess,
    ShiftsActions.fetchShiftSuccess,
    (state, { model }) => ({
      ...state,
      models: { ...state.models, [model._id]: model },
    }),
  ),
  on(
    ShiftsActions.reportNcnsSuccess,
    (state, { model, shiftId }) => ({
      ...state,
      models: {...state.models, 
        [shiftId]: {
          ...state.models[shiftId],
          applicants: {
            ...state.models[shiftId].applicants,
            [model.user.toString()]: model
          }

      } }
    })
  ),
  on(
    ShiftsActions.undoNcnsSuccess,
    (state, { model, shiftId }) => ({
      ...state,
      models: {...state.models, 
        [shiftId]: {
          ...state.models[shiftId],
          applicants: {
            ...state.models[shiftId].applicants,
            [model.user.toString()]: model
          }

      } }
    })
  ),
  on(
    ShiftsActions.deleteShiftSuccess,
    ShiftsActions.removeShiftFromStore,
    (state, { shiftId }) => {
      return {
        ...state,
        models: { ...state.models, [shiftId]: null },
        shifts: state.shifts.filter((s) => s !== shiftId),
      };
    },
  ),

  on(ShiftsActions.fetchPageFailed, ShiftsActions.fetchShiftsForScheduleFailed, (state, { error }) => {
    if (error.status === 404) {
      state = initialShiftsState;
    }

    return {
      ...state,
    };
  }),

  on(
    ShiftsActions.updateApplicantStatusToSuspended,
    (state, { applicantId, shiftId }) => {
      const shift = { ...state.models[shiftId] };
      if (!shift) {
        return {
          ...state,
        };
      }

      const applicantIndex = shift.applicants.findIndex(
        (a) => a.user._id === applicantId,
      );
      return {
        ...state,
          models: {
            ...state.models,
            [shiftId]: {
              ...shift,
              applicants: [
                ...shift.applicants.slice(0, applicantIndex),
                Object.assign({}, shift.applicants[applicantIndex], {
                    user: {
                      ...shift.applicants[applicantIndex].user,
                      isDisabled: true
                    },
                    status: 'suspended'

                }),
                ...state.models[shiftId].applicants.slice(applicantIndex + 1),
              ]
            }
          }

      };
    },
  ),
);


