import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IntercomService } from '../../../services/intercom.service';

@Component({
  selector: 'app-feature-banner',
  templateUrl: './feature-banner.component.html',
  styleUrls: ['./feature-banner.component.scss']
})
export class FeatureBannerComponent implements OnInit {
  @Input() text: string;
  @Input() articleId: string;
  @Input() color: string = 'rgba(16, 185, 129, 0.10)';
  @Input() icon: string = 'lightBulb';
  
  @Output() close = new EventEmitter();
  
  constructor(private intercomService: IntercomService,) { }

  ngOnInit(): void {
  }
  openArticle(){
    this.intercomService.openArticle(this.articleId);
  }
}
